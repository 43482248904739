<template>
    <div class="video-modal modal-bordered">
        <iframe
            :src="$content($store.getters.activeModalParams.url)"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </div>
</template>

<script>
export default {
  name: "Video",
  computed: {
    getVideoContentUrl() {
      this.$content(this.$store.getters.activeModalParams.url)
    },
    getVideoUrl() {
      this.$store.getters.activeModalParams.url
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .video-modal {
        padding: 35px 30px;
        height: 450px;
        background: white;

        @include only-lg-down {
            width: 100%;
        }

        @include only-sm {
            height: 300px;
        }

        @include only-xs {
           height: 300px;
            padding: 30px 15px;
        }

        @include only-minimal {
             height: 240px;
        }
    }
    iframe {
        width: 100%;
        height: 100%;
    }
</style>